
import LoginComponent from '@/components/landingAndRegistrationComponents/LoginComponent.vue';
import BaseButton from '@/components/landingAndRegistrationComponents/BaseButtonLanding.vue';
import { getNextPage } from '@/utils/redirectUtils';
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

@Component({
  components: { BaseButton, LoginComponent },
  computed: {
    ...mapState('calculateWindowWidth', ['windowWidth']),
  },
})
export default class LandingCreditScorePage extends Vue {
  public windowWidth!: number;

  get pictureSrc(): string {
    if (this.windowWidth < 1280 && this.windowWidth >= 764) return '_tablet';
    if (this.windowWidth > 0 && this.windowWidth < 764) return '_mobile';
    return '';
  }
  get checkAppsSrc(): string {
    if (this.windowWidth >= 1280) return '';
    return '_tablet';
  }

  goToRegistration(): void {
    this.$router.push({ name: getNextPage() });
  }

  mounted(): void {
    this.$store.commit('flowStages/setCurrentFlowAndStage', { flowVersion: '206', pageName: this.$route.name });
  }
}
